import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Sea from "./pages/Sea";
import Air from "./pages/Air";

import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Whyus from "./pages/Whyus";
import Hazardous from "./pages/Hazardous";
import Logistics from "./pages/Logistics";
import Industries from "./pages/Industries";
import Project from "./pages/Project";
import Rail from "./pages/Rail";
import Careers from "./pages/Careers";


import Road from "./pages/Road";


export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Whyus" element={<Whyus />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Sea" element={<Sea />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Road" element={<Road />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Hazardous" element={<Hazardous />} />
          <Route path="/Logistics" element={<Logistics />} />
          <Route path="/Industries" element={<Industries />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Rail" element={<Rail />} />

    
        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
