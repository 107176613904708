import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid header-bg py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-4 text-white mb-3 animated slideInDown">
            Road Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Road Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <img className="img-fluid" src="img/Road Freight.jpg" alt="" />
            <div
              className="col-lg-12 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}
            >
              <h1 className="display-5 mb-4">
                <span className="text-primary">Road Freight</span>
              </h1>
              <p className="mb-4">
                <p>
                  {companyname} understands the importance of timely and
                  reliable transportation services. We offer quick and precise
                  estimating and dispatch services, catering to a wide range of
                  shipment sizes and requirements, including single pallets,
                  full truckloads, long haul, regional, expedited, or dedicated
                  services. Our commitment to ensuring the performance, safety,
                  and equipment standards of your shipment is unwavering, and we
                  work closely with our service providers to achieve this.
                </p>

                <p>
                  Our team at {companyname} possesses in-depth knowledge of
                  regional business practices and is well-versed in legal and
                  regulatory requirements. We meticulously record and analyze
                  every significant event throughout the shipment's journey
                  using advanced technologies. This data-driven approach allows
                  us to continually improve our processes, tailoring them to the
                  specific needs of our clients' businesses.
                </p>

                <p>
                  {companyname} is dedicated to providing superior service while
                  optimizing transportation costs, whether you're shipping
                  perishable items or high-value goods.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
