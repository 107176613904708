import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid header-bg py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-4 text-white mb-3 animated slideInDown">
            Project Cargo
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Project Cargo
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <img className="img-fluid" src="img/Project Cargo.jpg" alt="" />
            <div
              className="col-lg-12 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}
            >
              <h1 className="display-5 mb-4">
                <span className="text-primary">Project Cargo</span>
              </h1>
              <p className="mb-4">
                <p>
                  Do you have heavy, massive, or intricate industrial facilities
                  that require specialized transport solutions? Look no further.{" "}
                  {companyname} is a leading logistics service provider
                  specializing in the transportation of materials and equipment
                  for project-based industries.
                </p>

                <p>
                  We offer comprehensive Integrated Turnkey Project Forwarding
                  Solutions for all types of locations, whether they are
                  central, remote, or hard-to-reach. Whether your shipment is
                  oversized or exceptionally heavy, our dedicated team of
                  experts meticulously plans, engineers, and coordinates all
                  logistics operations when you choose our services.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
