import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid header-bg py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-4 text-white mb-3 animated slideInDown">
            Contact Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Contact Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* Contact Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4 mb-5">
            <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
              <div className="h-100 bg-light d-flex align-items-center p-5">
                <div className="btn-lg-square bg-white flex-shrink-0">
                  <i className="fa fa-map-marker-alt text-primary" />
                </div>
                <div className="ms-4">
                  <p className="mb-2">
                    <span className="text-primary me-2">#</span>Address
                  </p>
                  <h5 className="mb-0">{companyaddress}</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
              <div className="h-100 bg-light d-flex align-items-center p-5">
                <div className="btn-lg-square bg-white flex-shrink-0">
                  <i className="fa fa-phone-alt text-primary" />
                </div>
                <div className="ms-4">
                  <p className="mb-2">
                    <span className="text-primary me-2">#</span>Call Now
                  </p>
                  <h5 className="mb-0">{companynumber}</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100 bg-light d-flex align-items-center p-5">
                <div className="btn-lg-square bg-white flex-shrink-0">
                  <i className="fa fa-envelope-open text-primary" />
                </div>
                <div className="ms-4  text-wrap">
                  <p className="mb-2">
                    <span className="text-primary me-2">#</span>Mail Now
                  </p>
                  <h6 className="mb-0 ">{companyemail}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p>
                <span className="text-primary me-2">#</span>Contact Us
              </p>
              <h1 className="display-5 mb-4">
                Have Any Query? Please Contact Us!
              </h1>
              <p className="mb-4">
                Unlock seamless shipping solutions today. Fill our contact form
                for tailored logistics that elevate your business.
              </p>
              <form
                id="contactForm"
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="control-group">
                      <input
                        className="form-control p-4 bg-light border-0"
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="control-group">
                      <input
                        className="form-control p-4 bg-light border-0"
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <input
                        className="form-control p-4 bg-light border-0"
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>{" "}
                  <div className="col-12">
                    <div className="control-group">
                      <input
                        className="form-control p-4 bg-light border-0"
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <textarea
                        className="form-control p-4 bg-light border-0"
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                      defaultValue=""
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <h5 style={{ color: "red" }}>{result}</h5>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100" style={{ minHeight: "400px" }}>
                <iframe
                  className="rounded w-100 h-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7542.846596393897!2d73.06176777770997!3d19.0451177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c210a5555555%3A0x84cbc1bf35955992!2sGLOBAL%20LIGHT%20ENTERPRISES!5e0!3m2!1sen!2sin!4v1696232456132!5m2!1sen!2sin"

                  frameBorder={0}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
               
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact End */}
      <Footer />
    </>
  );
}
