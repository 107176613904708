import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid header-bg py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-4 text-white mb-3 animated slideInDown">
          Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Services
              </li>
            </ol>
          </nav>
        </div>
      </div>
   
    {/* Membership Start */}
    <div className="container-xxl py-5">
        <div className="container">
          <div
            className="row g-5 mb-5 align-items-end wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="col-lg-12">
              <p>
                <span className="text-primary me-2">#</span>Services
              </p>
              <h1 className="display-5 mb-0">
              Explore our 
                <span className="text-primary"> comprehensive range of  </span>services
              </h1>
            </div>
           
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Sea Freight.jpg" alt="" />
                <h4 className="text-white mb-3">Sea Freight</h4>
                <p>
                Approximately 90% of the world's commodities are shipped
                  globally using ocean freight, also referred to as sea freight...
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Sea">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
                <h4 className="text-white mb-3">Air Freight</h4>
                <p>
                We understand that air freight is often considered a more
                  costly and premium service. However, thanks to our extensive
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Air">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Rail Freight.jpg" alt="" />
                <h4 className="text-white mb-3">Rail Freight</h4>
                <p>
                In India, the logistics industry relies heavily on railroads,
                  and unlike passenger trains, freight trains here do not adhere
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Rail">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Road Freight.jpg" alt="" />
                <h4 className="text-white mb-3">Road Freight</h4>
                <p>
                {companyname} understands the importance of timely and
                  reliable transportation services. We offer quick and precise
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Road">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Project Cargo.jpg" alt="" />
                <h4 className="text-white mb-3">Project Cargo</h4>
                <p>
                Do you have heavy, massive, or intricate industrial facilities
                  that require specialized transport solutions? Look no further.
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Project">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Logistics.jpg" alt="" />
                <h4 className="text-white mb-3">Logistics</h4>
                <p>
                Transporting oversized and overweight deliveries for
                  infrastructure logistics requires careful planning and coordination. 
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Logistics">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Membership End */}

      {/* Animal Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="row g-5 mb-5 align-items-end wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="col-lg-6">
              <p>
                <span className="text-primary me-2">#</span>Our Industry
                Solutions
              </p>
              <h1 className="display-5 mb-0">
                Explore <span className="text-primary">Our Diverse</span>{" "}
                Industry Solutions
              </h1>
            </div>
            <div className="col-lg-6 text-lg-end">
              <Link className="btn btn-primary py-3 px-5" to="/Getquote">
                Request A Quote
              </Link>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Agriculture.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Agriculture</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Automotive.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Automotive</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Fruits & Vegetables.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Fruits & Vegetables</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Mining & Minerals.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Mining & Minerals</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Chemicals, Petrochemicals & Plastics.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Chemicals, Petrochemicals & Plastics</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Food & Beverages.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Food & Beverages</h5>
                  </div>
                </div>
              </Link>
            </div>
            
          </div>
        </div>
      </div>
      {/* Animal End */}

      <Footer />
    </>
  );
}
