import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid header-bg py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-4 text-white mb-3 animated slideInDown">
            Air Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Air Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
            <div
              className="col-lg-12 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}
            >
              <h1 className="display-5 mb-4">
                <span className="text-primary">Air Freight</span>
              </h1>
              <p className="mb-4">
                <p>
                  We understand that air freight is often considered a more
                  costly and premium service. However, thanks to our extensive
                  network and access to available aircraft worldwide, we possess
                  the flexibility to design supply chain solutions that optimize
                  routing, cost-effectiveness, and delivery time.
                </p>

                <p>
                  Our strategic partnerships ensure consistent transit and lift
                  options, allowing us to leverage space allocation effectively.
                  When it comes to the quickest mode of transportation for
                  goods, air freight takes the lead. Thanks to the dedication of
                  our team members, our well-structured organization, and a
                  network of dependable partners, we can transport a wide range
                  of products to any destination across the globe. We handle air
                  transport orders with a focus on meeting customer requirements
                  while maintaining competitive pricing.
                </p>

                <p>
                  Our team members bring expertise in local business practices,
                  import and export procedures, peak-time capacity solutions, as
                  well as regulatory and compliance requirements, ensuring a
                  smooth and hassle-free air freight experience.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
