import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid header-bg py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-4 text-white mb-3 animated slideInDown">
            Sea Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Sea Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <img className="img-fluid" src="img/Sea Freight.jpg" alt="" />
            <div
              className="col-lg-12 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}
            >
              <h1 className="display-5 mb-4">
                <span className="text-primary">Sea Freight</span>
              </h1>
              <p className="mb-4">
                <p>
                  Approximately 90% of the world's commodities are shipped
                  globally using ocean freight, also referred to as sea freight.
                  It is the preferred and widely adopted mode of transportation
                  for importers and exporters worldwide. {companyname} excels in
                  providing highly adaptable, reliable, and specialized
                  solutions within the realm of ocean freight services.
                </p>

                <p>
                  We maintain a comprehensive service network that covers
                  virtually all major seaport destinations, allowing us to cater
                  to specific marine freight transport requirements. Our team
                  comprises experienced associates who are well-equipped to
                  guide you through the complexities of shipping logistics.
                  Among the numerous services we offer, you can benefit from
                  weekly sailing schedules and space allocation during peak
                  demand periods.
                </p>

                <p>
                  Our extensive networks and in-depth local knowledge empower us
                  to handle all essential documentation, facilitate
                  consolidation and deconsolidation processes, provide flexible
                  scheduling options, and offer competitive rates for your
                  shipping needs.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
