import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid header-bg py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-4 text-white mb-3 animated slideInDown">
          Why Choose Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Why Choose Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
    {/* Service Start */}
    <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-6">
              <p>
                <span className="text-primary me-2">#</span>Why Choose Us
              </p>
              <h1 className="display-5 mb-0">
                Few Reasons
                <span className="text-primary"> Why People </span> Choose Us!
              </h1>
            </div>
            <div className="col-lg-6">
              <div className="bg-primary h-100 d-flex align-items-center py-4 px-4 px-sm-5">
                <i className="fa fa-3x fa-mobile-alt text-white" />
                <div className="ms-4">
                  <p className="text-white mb-0">Call for more info</p>
                  <h2 className="text-white mb-0">{companynumber}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-5 gx-4">
            <div className="col-lg-6 p-2">
              <p>
                We are deeply committed to our clients and prioritize delivering
                high-quality services.
              </p>

              <p>
                Allied Seafresight Logistics offers exceptional services for all
                your goods forwarding needs, ensuring affordability without
                compromising on quality.
              </p>

              <p>
                Our skilled Customs Clearance staff ensures the smooth and
                cost-effective clearance of your cargoes, saving you time and
                hassle.
              </p>

              <p>
                We understand that logistics often operate outside regular
                business hours, providing you with greater freedom and
                flexibility.
              </p>

              <p>
                With our extensive network and expertise in efficient routes, we
                can assist in the prompt and reliable delivery of your cargo.
              </p>
            </div>
            <div className="col-lg-6 p-4 row">
              <div
                className="col-lg-6 col-md-4 col-sm-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  className="img-fluid mb-3"
                  src="img/icon1.png"
                  alt="Icon"
                />
                <h5 className="mb-3">Reliable</h5>
                <span>Count on us for dependable logistics solutions</span>
              </div>
              <div
                className="col-lg-6 col-md-4 col-sm-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  className="img-fluid mb-3"
                  src="img/icon2.png"
                  alt="Icon"
                />
                <h5 className="mb-3">Great Prices</h5>
                <span>Discover cost-effective shipping options with us</span>
              </div>
              <div
                className="col-lg-6 col-md-4 col-sm-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  className="img-fluid mb-3"
                  src="img/icon3.png"
                  alt="Icon"
                />
                <h5 className="mb-3">On-Time Shipping</h5>
                <span>We ensure punctual delivery for your shipments</span>
              </div>
              <div
                className="col-lg-6 col-md-4 col-sm-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  className="img-fluid mb-3"
                  src="img/icon4.png"
                  alt="Icon"
                />
                <h5 className="mb-3">Networks</h5>
                <span>
                  Benefit from our extensive global logistics network.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
      {/* Animal Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="row g-5 mb-5 align-items-end wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="col-lg-6">
              <p>
                <span className="text-primary me-2">#</span>Our Industry
                Solutions
              </p>
              <h1 className="display-5 mb-0">
                Explore <span className="text-primary">Our Diverse</span>{" "}
                Industry Solutions
              </h1>
            </div>
            <div className="col-lg-6 text-lg-end">
              <Link className="btn btn-primary py-3 px-5" to="/Getquote">
                Request A Quote
              </Link>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Agriculture.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Agriculture</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Automotive.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Automotive</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Fruits & Vegetables.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Fruits & Vegetables</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Mining & Minerals.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Mining & Minerals</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Chemicals, Petrochemicals & Plastics.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Chemicals, Petrochemicals & Plastics</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Food & Beverages.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Food & Beverages</h5>
                  </div>
                </div>
              </Link>
            </div>
            
          </div>
        </div>
      </div>
      {/* Animal End */}

      <Footer />
    </>
  );
}
