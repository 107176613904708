import { Outlet, Link } from "react-router-dom";
import Getquote from "../pages/Getquote";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = (
  {
    /*  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };
*/
  }
) => {
  return (
    <>
      {/* Topbar Start */}
      <div
        className="container-fluid bg-light p-0 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center py-3 me-4">
              <small className="fa fa-map-marker-alt text-primary me-2" />
              <small> Kharghar, Navi Mumbai, 410210</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center py-3">
              <small className="far fa-envelope text-primary me-2" />
              <small>{companyemail}</small>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center py-3 me-4">
              <small className="fa fa-phone-alt text-primary me-2" />
              <small>{companynumber}</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center">
              <Link className="" to="/Privacy">
                Privacy Policy
              </Link>
              <Link className="ps-2" to="/Terms">
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}
      {/* Navbar Start */}
      <nav
        className="navbar navbar-expand-lg bg-white navbar-light sticky-top py-lg-0 px-4 px-lg-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <Link to="/" className="navbar-brand p-0">
          <img src="img/logo.png" alt="Icon" style={{ height: "100px" }} />
        </Link>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse py-4 py-lg-0"
          id="navbarCollapse"
        >
          <div className="navbar-nav ms-auto">
           
          <Link to="/Home" className="nav-item nav-link ">
              Home
            </Link>
            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Services
              </Link>
              <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                <Link to="/Sea" className="dropdown-item">
                  Sea Freight
                </Link>
                <Link to="/Air" className="dropdown-item">
                  Air Freight
                </Link>
                <Link to="/Rail" className="dropdown-item">
                  Rail Freight
                </Link>
                <Link to="/Road" className="dropdown-item">
                  Road Freight
                </Link>
                <Link to="/Project" className="dropdown-item">
                  Project Cargo
                </Link>
                <Link to="/Logistics" className="dropdown-item">
                  Logistics
                </Link>
              </div>
            </div>
            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Industry Solutions
              </Link>
              <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                <Link to="/Industries" className="dropdown-item">
                  Agriculture
                </Link>
                <Link to="/Industries" className="dropdown-item">
                  Automotive
                </Link>
                <Link to="/Industries" className="dropdown-item">
                  Fruits & Vegetables
                </Link>
                <Link to="/Industries" className="dropdown-item">
                  Mining & Minerals
                </Link>
                <Link to="/Industries" className="dropdown-item">
                  Chemicals, Petrochemicals & Plastics
                </Link>
                <Link to="/Industries" className="dropdown-item">
                  Food & Beverages
                </Link>
              </div>
            </div>
            <Link to="/Contact" className="nav-item nav-link">
              Contact Us
            </Link>
            <Link to="/About" className="nav-item nav-link">
              About Company
            </Link>
            
          <Link to="/Whyus" className="nav-item nav-link ">
              Why Choose Us
            </Link>
           
          </div>
          <Link to="/Getquote" className="btn btn-primary">
            Request For Quote
            <i className="fa fa-arrow-right ms-3" />
          </Link>
        </div>
      </nav>
      {/* Navbar End */}
    </>
  );
};

export default Header;
