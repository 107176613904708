import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid header-bg py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-4 text-white mb-3 animated slideInDown">
          About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                 About Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
       {/* About Start */}
       <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p>
                <span className="text-primary me-2">#</span>Welcome To{" "}
                {companyname}
              </p>
              <h1 className="display-5 mb-4">
                Taking
                <span className="text-primary"> Freight Forwarding</span> to New
                Heights.
              </h1>
              <p className="mb-4">
                {companyname} is a versatile company that specializes in both
                freight forwarding and trading services. This unique combination
                allows us to offer comprehensive solutions to our clients,
                addressing their logistics needs while also providing access to
                trading opportunities. Our expertise in these areas ensures that
                we can deliver tailored and efficient services that meet the
                diverse demands of our valued customers. Whether it's
                facilitating the movement of goods or exploring trading
                prospects, {companyname} is your trusted partner in the world of
                logistics and trade.
              </p>

             
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="img-border">
                <img className="img-fluid" src="img/about 1.jpg" alt="" />
              </div>
            </div>
          </div>
          
          <p className="mb-4">
                <p>
                  The foundation of {companyname}' success lies in the dedicated
                  efforts and expertise of its team members. It is the hard
                  work, commitment, and skills of the individuals within the
                  organization that enable {companyname} to provide exceptional
                  service and maintain competitive pricing. Each member of the
                  team plays a vital role in delivering on the company's
                  promises, ensuring that customers receive reliable and
                  cost-effective logistics solutions.
                </p>
              </p>
        </div>
      </div>
      {/* About End */}
        {/* Membership Start */}
        <div className="container-xxl py-5">
        <div className="container">
          <div
            className="row g-5 mb-5 align-items-end wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="col-lg-12">
              <p>
                <span className="text-primary me-2">#</span>Services
              </p>
              <h1 className="display-5 mb-0">
              Explore our 
                <span className="text-primary"> comprehensive range of  </span>services
              </h1>
            </div>
           
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Sea Freight.jpg" alt="" />
                <h4 className="text-white mb-3">Sea Freight</h4>
                <p>
                Approximately 90% of the world's commodities are shipped
                  globally using ocean freight, also referred to as sea freight...
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Sea">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
                <h4 className="text-white mb-3">Air Freight</h4>
                <p>
                We understand that air freight is often considered a more
                  costly and premium service. However, thanks to our extensive
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Air">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Rail Freight.jpg" alt="" />
                <h4 className="text-white mb-3">Rail Freight</h4>
                <p>
                In India, the logistics industry relies heavily on railroads,
                  and unlike passenger trains, freight trains here do not adhere
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Rail">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Road Freight.jpg" alt="" />
                <h4 className="text-white mb-3">Road Freight</h4>
                <p>
                {companyname} understands the importance of timely and
                  reliable transportation services. We offer quick and precise
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Road">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Project Cargo.jpg" alt="" />
                <h4 className="text-white mb-3">Project Cargo</h4>
                <p>
                Do you have heavy, massive, or intricate industrial facilities
                  that require specialized transport solutions? Look no further.
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Project">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Logistics.jpg" alt="" />
                <h4 className="text-white mb-3">Logistics</h4>
                <p>
                Transporting oversized and overweight deliveries for
                  infrastructure logistics requires careful planning and coordination. 
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Logistics">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Membership End */}

      <Footer />
    </>
  );
}
