import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid header-bg py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-4 text-white mb-3 animated slideInDown">
            Industry Solutions
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Industry Solutions
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p>
                <span className="text-primary me-2">#</span>Industry Solutions
              </p>
              <h1 className="display-5 mb-4">
                <span className="text-primary">Agriculture</span>
              </h1>
              <p className="mb-4">
                <p>
                  Agricultural commodities, including cashews, cocoa, coffee,
                  rice, and soybeans, demand meticulous attention at every stage
                  of their transportation to maintain their freshness, flavor,
                  texture, and fragrance. These valuable products undergo a
                  complex journey from farm to market, and it's essential to
                  ensure that they reach consumers in the best possible
                  condition.
                </p>

                <p>
                  {companyname}'s commitment to sustainability, coupled with its
                  state-of-the-art and eco-friendly fleet, empowers us to
                  expertly manage, store, and convey various types of dry and
                  food-grade cargo. Our goal is to uphold the quality and
                  freshness of your perishable agricultural goods throughout
                  their entire voyage. With our advanced logistical solutions
                  and environmentally conscious approach, you can trust us to
                  safeguard the integrity of your valuable produce from origin
                  to destination.
                </p>
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="img-border">
                <img className="img-fluid" src="img/Agriculture.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="img-border">
                <img className="img-fluid" src="img/Automotive.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p>
                <span className="text-primary me-2">#</span>Industry Solutions
              </p>
              <h1 className="display-5 mb-4">
                <span className="text-primary">Automotive</span>
              </h1>
              <p className="mb-4">
                <p>
                  Whether you're in the process of transporting production or
                  service parts, automobiles, trucks, or electric vehicles,
                  having a dependable and seasoned shipping partner is a
                  critical component of maintaining an uninterrupted supply
                  chain. In today's rapidly evolving automotive industry, which
                  is progressively transitioning into a greener era,{" "}
                  {companyname} stands ready to deliver the swift, adaptable,
                  and transparent supply chain solutions essential to your
                  success.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p>
                <span className="text-primary me-2">#</span>Industry Solutions
              </p>
              <h1 className="display-5 mb-4">
                <span className="text-primary">Fruits & Vegetables</span>
              </h1>
              <p className="mb-4">
                <p>
                  In today's consumer-driven market, there is a growing demand
                  for a continuous supply of fresh, flavorful, and visually
                  appealing fruits. Safely transporting these fragile perishable
                  goods from the farm to their ultimate destination while
                  maintaining their taste, freshness, texture, and aroma demands
                  a combination of speed, adaptability, and advanced temperature
                  control technology.
                </p>
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="img-border">
                <img
                  className="img-fluid"
                  src="img/Fruits & Vegetables.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="img-border">
                <img
                  className="img-fluid"
                  src="img/Mining & Minerals.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p>
                <span className="text-primary me-2">#</span>Industry Solutions
              </p>
              <h1 className="display-5 mb-4">
                <span className="text-primary">Mining & Minerals</span>
              </h1>
              <p className="mb-4">
                <p>
                  For decades, {companyname} has been effectively bridging the
                  gap between the mining and minerals extraction sectors and
                  customer markets worldwide. Given that mining and mineral
                  products play a pivotal role in numerous industries, including
                  construction, technology, manufacturing, and energy
                  production, {companyname}'s capacity to provide swift and
                  dependable transit times is vital in ensuring the continuity
                  of your supply chain.
                </p>

                <p>
                  As the global demand for essential commodities like graphite,
                  iron, steel, copper, or ore continues to surge, it becomes
                  increasingly crucial to collaborate with a shipping partner
                  well-versed in the unique challenges faced by the industry.
                  For instance, when natural disasters or geopolitical tensions
                  threaten to disrupt your overall supply chain, partnering with
                  an industry expert can help you navigate these obstacles and
                  swiftly get your supply chain back on course.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p>
                <span className="text-primary me-2">#</span>Industry Solutions
              </p>
              <h1 className="display-5 mb-4">
                <span className="text-primary">
                  Chemicals, Petrochemicals & Plastics
                </span>
              </h1>
              <p className="mb-4">
                <p>
                  At {companyname}, we employ meticulous, accurate, and robust
                  procedures to securely transport hazardous and potentially
                  dangerous goods, including chemicals and petrochemicals. As
                  specialists in the field of transporting such cargoes,{" "}
                  {companyname} is dedicated to ensuring the safe and
                  regulatory-compliant shipment of your goods. Our expertise
                  extends to managing unforeseen situations regardless of where
                  your shipments are headed worldwide.
                </p>
              </p>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="img-border">
                <img
                  className="img-fluid"
                  src="img/Chemicals, Petrochemicals & Plastics.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="img-border">
                <img
                  className="img-fluid"
                  src="img/Food & Beverages.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p>
                <span className="text-primary me-2">#</span>Industry Solutions
              </p>
              <h1 className="display-5 mb-4">
                <span className="text-primary">Food & Beverages</span>
              </h1>
              <p className="mb-4">
                <p>
                  Meat, seafood, fruits, vegetables, and beverages like coffee,
                  wine, and spirits are among the world's most sought-after
                  commodities. However, transporting them can be highly
                  challenging due to factors like limited shelf-life and the
                  unpredictability of seasonal harvests.
                </p>

                <p>
                  With decades of experience serving the food and beverage
                  industries, {companyname} possesses a deep understanding of
                  the sector's distinct requirements. This includes recognizing
                  the significance of global port coverage, the availability of
                  specialized equipment, and the importance of dependable
                  scheduling to ensure the seamless movement of these valuable
                  goods.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
