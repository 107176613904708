import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid header-bg py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-4 text-white mb-3 animated slideInDown">
            Logistics
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Logistics
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <img className="img-fluid" src="img/Logistics.jpg" alt="" />
            <div
              className="col-lg-12 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}
            >
              <h1 className="display-5 mb-4">
                <span className="text-primary">Logistics</span>
              </h1>
              <p className="mb-4">
                <p>
                  Transporting oversized and overweight deliveries for
                  infrastructure logistics requires careful planning and
                  coordination. {companyname} specializes in delivering all the
                  necessary machinery, building supplies, and equipment from
                  various sourcing points to project locations using multiple
                  transportation modes, including sea, river, road, and air.
                </p>

                <p>
                  We provide complete logistical support for odd Dimension
                  Cargo, which often requires specialized equipment and
                  logistical assistance. This includes conducting route surveys
                  to identify potential bottlenecks, such as unstable bridges,
                  sharp curves, steep gradients, and vertical and lateral
                  obstacles. We also secure the necessary approvals from
                  government authorities to implement temporary solutions to
                  overcome these bottlenecks and ensure smooth transit for the
                  cargo.
                </p>

                <p>
                  Our services extend to building temporary bridges, jetties,
                  and other structures specifically designed to facilitate the
                  movement of oversized loads. We have a fleet of specialized
                  transport vehicles and vessels to meet all site requirements.
                  When it comes to handling complex logistics for infrastructure
                  projects, you need a single-window logistics services provider
                  like {companyname}.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
