import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };

  const handlesubjectChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      {/* Slider Area */}
      <section className="slider w3-banner jarallax">
        <video autoPlay loop muted>
          <source src="img/video.mp4"></source>
        </video>
      </section>

      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p>
                <span className="text-primary me-2">#</span>Welcome To{" "}
                {companyname}
              </p>
              <h1 className="display-5 mb-4">
                Taking
                <span className="text-primary"> Freight Forwarding</span> to New
                Heights.
              </h1>
              <p className="mb-4">
                {companyname} is a versatile company that specializes in both
                freight forwarding and trading services. This unique combination
                allows us to offer comprehensive solutions to our clients,
                addressing their logistics needs while also providing access to
                trading opportunities. Our expertise in these areas ensures that
                we can deliver tailored and efficient services that meet the
                diverse demands of our valued customers. Whether it's
                facilitating the movement of goods or exploring trading
                prospects, {companyname} is your trusted partner in the world of
                logistics and trade.
              </p>

              <Link className="btn btn-primary py-3 px-5 mt-3" to="/About">
                Read More
              </Link>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="img-border">
                <img className="img-fluid" src="img/about 1.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      
      {/* Membership Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="row g-5 mb-5 align-items-end wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="col-lg-12">
              <p>
                <span className="text-primary me-2">#</span>Services
              </p>
              <h1 className="display-5 mb-0">
              Explore our 
                <span className="text-primary"> comprehensive range of  </span>services
              </h1>
            </div>
           
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Sea Freight.jpg" alt="" />
                <h4 className="text-white mb-3">Sea Freight</h4>
                <p>
                Approximately 90% of the world's commodities are shipped
                  globally using ocean freight, also referred to as sea freight...
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Sea">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
                <h4 className="text-white mb-3">Air Freight</h4>
                <p>
                We understand that air freight is often considered a more
                  costly and premium service. However, thanks to our extensive
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Air">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Rail Freight.jpg" alt="" />
                <h4 className="text-white mb-3">Rail Freight</h4>
                <p>
                In India, the logistics industry relies heavily on railroads,
                  and unlike passenger trains, freight trains here do not adhere
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Rail">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Road Freight.jpg" alt="" />
                <h4 className="text-white mb-3">Road Freight</h4>
                <p>
                {companyname} understands the importance of timely and
                  reliable transportation services. We offer quick and precise
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Road">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Project Cargo.jpg" alt="" />
                <h4 className="text-white mb-3">Project Cargo</h4>
                <p>
                Do you have heavy, massive, or intricate industrial facilities
                  that require specialized transport solutions? Look no further.
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Project">
                  Learn More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="membership-item position-relative">
                <img className="img-fluid" src="img/Logistics.jpg" alt="" />
                <h4 className="text-white mb-3">Logistics</h4>
                <p>
                Transporting oversized and overweight deliveries for
                  infrastructure logistics requires careful planning and coordination. 
                </p>
               
                <Link className="btn btn-outline-light px-4 mt-3" to="/Logistics">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Membership End */}

      {/* Service Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-6">
              <p>
                <span className="text-primary me-2">#</span>Why Choose Us
              </p>
              <h1 className="display-5 mb-0">
                Few Reasons
                <span className="text-primary"> Why People </span> Choose Us!
              </h1>
            </div>
            <div className="col-lg-6">
              <div className="bg-primary h-100 d-flex align-items-center py-4 px-4 px-sm-5">
                <i className="fa fa-3x fa-mobile-alt text-white" />
                <div className="ms-4">
                  <p className="text-white mb-0">Call for more info</p>
                  <h2 className="text-white mb-0">{companynumber}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-5 gx-4">
            <div className="col-lg-6 p-2">
              <p>
                We are deeply committed to our clients and prioritize delivering
                high-quality services.
              </p>

              <p>
                Allied Seafresight Logistics offers exceptional services for all
                your goods forwarding needs, ensuring affordability without
                compromising on quality.
              </p>

              <p>
                Our skilled Customs Clearance staff ensures the smooth and
                cost-effective clearance of your cargoes, saving you time and
                hassle.
              </p>

              <p>
                We understand that logistics often operate outside regular
                business hours, providing you with greater freedom and
                flexibility.
              </p>

              <p>
                With our extensive network and expertise in efficient routes, we
                can assist in the prompt and reliable delivery of your cargo.
              </p>
            </div>
            <div className="col-lg-6 p-4 row">
              <div
                className="col-lg-6 col-md-4 col-sm-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  className="img-fluid mb-3"
                  src="img/icon1.png"
                  alt="Icon"
                />
                <h5 className="mb-3">Reliable</h5>
                <span>Count on us for dependable logistics solutions</span>
              </div>
              <div
                className="col-lg-6 col-md-4 col-sm-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  className="img-fluid mb-3"
                  src="img/icon2.png"
                  alt="Icon"
                />
                <h5 className="mb-3">Great Prices</h5>
                <span>Discover cost-effective shipping options with us</span>
              </div>
              <div
                className="col-lg-6 col-md-4 col-sm-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  className="img-fluid mb-3"
                  src="img/icon3.png"
                  alt="Icon"
                />
                <h5 className="mb-3">On-Time Shipping</h5>
                <span>We ensure punctual delivery for your shipments</span>
              </div>
              <div
                className="col-lg-6 col-md-4 col-sm-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  className="img-fluid mb-3"
                  src="img/icon4.png"
                  alt="Icon"
                />
                <h5 className="mb-3">Networks</h5>
                <span>
                  Benefit from our extensive global logistics network.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
      {/* Animal Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="row g-5 mb-5 align-items-end wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="col-lg-6">
              <p>
                <span className="text-primary me-2">#</span>Our Industry
                Solutions
              </p>
              <h1 className="display-5 mb-0">
                Explore <span className="text-primary">Our Diverse</span>{" "}
                Industry Solutions
              </h1>
            </div>
            <div className="col-lg-6 text-lg-end">
              <Link className="btn btn-primary py-3 px-5" to="/Getquote">
                Request A Quote
              </Link>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Agriculture.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Agriculture</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Automotive.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Automotive</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Fruits & Vegetables.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Fruits & Vegetables</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Mining & Minerals.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Mining & Minerals</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Chemicals, Petrochemicals & Plastics.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Chemicals, Petrochemicals & Plastics</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                className="animal-item"
                to="/Industries"
                data-lightbox="animal"
              >
                <div className="position-relative">
                  <img className="img-fluid" src="img/Food & Beverages.jpg" alt="" />
                  <div className="animal-text p-4">
                    <p className="text-white small text-uppercase mb-0">
                      Industries
                    </p>
                    <h5 className="text-white mb-0">Food & Beverages</h5>
                  </div>
                </div>
              </Link>
            </div>
            
          </div>
        </div>
      </div>
      {/* Animal End */}

      

      <Footer />
    </>
  );
}
