import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid header-bg py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-4 text-white mb-3 animated slideInDown">
            Rail Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Rail Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <img className="img-fluid" src="img/Rail Freight.jpg" alt="" />
            <div
              className="col-lg-12 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}
            >
              <h1 className="display-5 mb-4">
                <span className="text-primary">Rail Freight</span>
              </h1>
              <p className="mb-4">
                <p>
                  In India, the logistics industry relies heavily on railroads,
                  and unlike passenger trains, freight trains here do not adhere
                  to fixed schedules. This dynamic nature of freight operations
                  in the country demands a high level of information management.
                </p>

                <p>
                  At {companyname}, we understand these intricacies and are
                  fully equipped to handle rail freights. We have dedicated
                  teams stationed at major Inland Container Depots (ICDs) to
                  manage time-sensitive and logistical tasks efficiently,
                  ensuring compliance and smooth operations.
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
